<template>
    <ServiceEntryForm
        title="Motorölwechsel"
        description="Details zum Motorölwechsel eingeben und eine Erinnerung festlegen."
        :fields="fields"
        @update="handleFormUpdate"
    />
</template>

<script>
import ServiceEntryForm from '../ServiceEntryForm.vue';
import { addYears } from 'date-fns';

export default {
    components: {
        ServiceEntryForm,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        fields() {
            return [
                {
                    key: 'date',
                    label: 'Datum des Ölwechsels',
                    type: 'date',
                    defaultValue: new Date().toISOString().substring(0, 10),
                    placeholder: 'Wählen Sie ein Datum',
                },
                {
                    key: 'mileage',
                    label: 'KM-Stand bei Ölwechsel',
                    type: 'number',
                    defaultValue: '',
                    required: true,
                    placeholder: 'KM-Stand eingeben',
                    min: 0,
                },
                {
                    key: 'mileageLimit',
                    label: 'Verbleibende Laufleistung in KM',
                    type: 'number',
                    defaultValue: 20000,
                    placeholder: 'Laufleistung',
                    min: 0,
                },
                {
                    key: 'mileageMargin',
                    label: 'Erinnerung',
                    type: 'reminder',
                    allowedUnits: [{ label: 'KM', value: 'km' }],
                    defaultValue: { number: 10000, unit: 'km' },
                },
            ].map(field => {
                if (field.key === 'mileageMargin') {
                    field.defaultValue = {
                        number: this.data.mileageMargin || 10000,
                        unit: 'km',
                    };
                } else {
                    field.defaultValue = this.data[field.key] || field.defaultValue;
                }
                return field;
            });
        },
    },
    methods: {
        handleFormUpdate(formData) {
            formData = {
                ...formData,
                mileageMargin: formData.mileageMargin.number || 10000,
            };
            this.$emit('update', formData);
        },
    },
};
</script>
