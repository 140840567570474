var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ServiceEntryForm', {
    attrs: {
      "title": "Reifenwechsel",
      "description": "Details zum Reifenwechsel eingeben und eine Erinnerung festlegen.",
      "fields": _vm.fields
    },
    on: {
      "update": _vm.handleFormUpdate
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }