var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ServiceEntryForm', {
    attrs: {
      "title": "Fahrzeuginspektion",
      "description": "Geben Sie die Details der Fahrzeuginspektion ein und legen Sie Erinnerungen fest.",
      "fields": _vm.fields
    },
    on: {
      "update": _vm.handleFormUpdate
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }