var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v(_vm._s(_vm.title))]), _c('p', [_vm._v(_vm._s(_vm.description))]), _c('br'), _vm._l(_vm.fields, function (field) {
    return _c('div', {
      key: field.key,
      staticClass: "form-group"
    }, [field.type === 'reminder' ? _c('p', {
      staticStyle: {
        "margin": "20px 10px"
      }
    }, [_vm._v(" Legen Sie eine Erinnerung fest ")]) : _vm._e(), field.type === 'reminder' ? _c('ReminderInput', {
      attrs: {
        "value": field.defaultValue,
        "allowedUnits": field.allowedUnits
      },
      on: {
        "update": _vm.handleReminderInput
      }
    }) : _c('Input', {
      attrs: {
        "label": field.label,
        "marginTop": field.marginTop || 4,
        "type": field.type,
        "value": _vm.formData[field.key],
        "pattern": field.pattern,
        "min": field.min,
        "options": field.options,
        "max": field.max,
        "placeholder": field.placeholder,
        "keyName": field.key,
        "isDisabled": field.isDisabled || false,
        "direction": field.direction || 'vertical'
      },
      on: {
        "onKeyPress": _vm.handleKeyPress
      }
    })], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }