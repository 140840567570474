var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "service-entry-table-wrap"
  }, [_vm._l(_vm.groupedHistory, function (group, month) {
    return _c('div', {
      key: month,
      staticClass: "month-section"
    }, [_c('div', {
      staticClass: "month-header"
    }, [_c('h4', [_vm._v(_vm._s(month))])]), _c('Row', {
      staticClass: "row-header",
      attrs: {
        "parentHover": false,
        "items": {
          row: _vm.header
        },
        "index": -1
      }
    }), _vm._l(group.entries, function (entry, index) {
      return _c('Row', {
        key: entry.uuid,
        attrs: {
          "hasBadge": true,
          "items": entry,
          "isDisabled": entry.isDisabled,
          "index": index
        },
        on: {
          "onHover": function onHover(args) {
            return _vm.$emit('onRowHover', args);
          },
          "onLeave": function onLeave(args) {
            return _vm.$emit('onRowLeave', args);
          },
          "onClick": function onClick($event) {
            return _vm.$emit('onHistoryEntryClick', entry);
          }
        }
      });
    })], 2);
  }), !_vm.entries.length ? _c('EmptyState', {
    attrs: {
      "message": "Es wurden noch keine Service Einträge angelegt.<br> Klicke auf den Button um einen neuen Service Eintrag anzulegen."
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }