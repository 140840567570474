<!-- eslint-disable vue/no-unused-components -->
<template>
    <div class="Car">
        <BackArrow variant="black" :to="'/cars'" />
        <Headline>
            <br />
            <h1>Fahrzeug {{ carId }}</h1>
            <p>
                Hersteller: {{ car.make }} | Modell: {{ car.model }} | Baujahr:
                {{ car.year }}
            </p>
        </Headline>
        <!-- <p v-html="totalIncomeString" />
        <p v-html="totalRepairCostString" /> -->
        <Toolbar class="Toolbar">
            <div></div>
            <!-- <div>
                <Dropdown
                    :items="repairCategories"
                    :selected="selectedCategory && selectedCategory.value"
                    placeholder="Kategorie auswählen"
                    @onItemSelect="handleCategorySelect"
                />
            </div> -->
            <div>
                <Button
                    @onClick="openNewEntryModal"
                    :isDisabled="!car.currentInstallation"
                    v-tooltip="
                        car.currentInstallation
                            ? 'Eintrag erstellen'
                            : 'Das Fahrzeug hat kein aktives Gerät.'
                    "
                    >Neuen Eintrag erstellen</Button
                >
            </div>
        </Toolbar>
        <br />

        <!-- <div class="CategorySpendBar">
            <div
                class="CategoryChunk"
                v-for="category in spendByCategory"
                :key="category.id"
                :id="category.id"
                :style="{ width: category.width + '%', backgroundColor: category.color }"
                @click="selectedCategory = category"
            >
                <span class="CategoryChunkLabel">
                    <span>{{ category.value }}</span>
                    <span class="Spend">{{ priceToEuroString(category.spend) }}</span>
                </span>
                <div class="Tooltip">{{ category.value }}: {{ category.width.toFixed(2) }}%</div>
            </div>
        </div> -->
        <!-- <div class="RepairHistory">
            <CarRepairTable
                :repairHistory="filteredRepairHistory"
                @onHistoryEntryClick="handleEntryClick"
            />
        </div> -->
        <ServiceEntryTable
            :entries="actionHistory"
            @onServiceEntryClick="handleEntryClick"
            @onServiceEntryRemove="handleEntryRemove"
        />

        <Modal
            :show="isModalOpen"
            :isLoading="isAddingEntry"
            :title="'Eintrag für Fahrzeug ' + (car.id || '')"
            :action="
                selectedService
                    ? { text: 'Speichern', color: 'green', callback: () => handleServiceAdd() }
                    : { text: '', color: '', callback: () => {} }
            "
            :cancel="
                selectedService
                    ? { text: 'Zurück', callback: () => resetSelectedService() }
                    : { text: 'Abbrechen', callback: closeModal }
            "
            @onModalClose="closeModal"
        >
            <p v-if="!selectedService" style="margin-bottom: 20px;">
                Bitte wählen Sie den gewünschten Service aus.
            </p>
            <ServiceSelection
                @update-service="updateSelectedService"
                :data="data"
                v-if="!selectedService"
            />
            <DeviceCalibration
                v-if="selectedService === 'deviceCalibration'"
                :data="data"
                @update="updateData"
            />
            <EngineOilChange
                v-if="selectedService === 'engineOilChange'"
                :data="data"
                @update="updateData"
            />
            <CarService v-if="selectedService === 'carService'" @update="updateData" :data="data" />
            <TuvInspection
                v-if="selectedService === 'tuvInspection'"
                :data="data"
                @update="updateData"
            />

            <TransmissionOilChange
                v-if="selectedService === 'transmissionOilChange'"
                :data="data"
                @update="updateData"
            />
            <WheelChange
                v-if="selectedService === 'wheelChange'"
                :data="data"
                @update="updateData"
            />
        </Modal>

        <!-- <Modal
            :show="isModalOpen"
            :title="'Reparatureintrag für Fahrzeug ' + (car.id || '')"
            :action="{ text: 'Speichern', color: 'green', callback: addRepairEntry }"
            :cancel="{ text: 'Abbrechen', callback: closeModal }"
            @onModalClose="closeModal"
        >
            <div class="InputWrap">
                <label for="repairCategory">Kategorie</label>
                <Dropdown
                    variant="single-column"
                    :items="repairCategories"
                    :selected="newRepair.category"
                    placeholder="Kategorie auswählen"
                    @onItemSelect="handleRepairCategorySelect"
                />
                <input
                    v-if="newRepair.category && newRepair.category.id === 'other'"
                    class="Input"
                    id="repairDescription"
                    v-model="newRepair.description"
                    placeholder="Eigene Kategorie"
                    required
                />
                <span class="Hint">Die Kategorie wird für die Auswertung benötigt.</span>
            </div>

            <div class="InputWrap">
                <label for="repairDate">Datum</label>
                <input
                    class="Input"
                    type="date"
                    id="repairDate"
                    v-model="newRepairDate"
                    placeholder="Reparatur Datum"
                    required
                />
            </div>

            <div class="InputWrap">
                <label for="partCost">Kosten für Ersatzteile</label>
                <div class="Row">
                    <input
                        class="Input"
                        type="number"
                        min="0"
                        id="partCost"
                        v-model.number="newRepair.partCost"
                        placeholder="Preis der Ersatzteile"
                    />
                    <Dropdown
                        variant="single-column"
                        :hasRemoveIcon="false"
                        :items="taxRates"
                        :selected="newRepair.partTax"
                        placeholder="Mwst auswählen"
                        @onItemSelect="handlePartTaxSelect"
                    />
                </div>
                <span class="Hint">
                    Den Preis in Euro angeben.
                </span>
            </div>

            <div class="InputWrap">
                <label for="laborCost">Preis für den Arbeitswert</label>
                <div class="Row">
                    <input
                        class="Input"
                        type="number"
                        min="0"
                        id="laborCost"
                        v-model.number="newRepair.laborCost"
                        placeholder="Preis für den Arbeitswert"
                    />
                    <Dropdown
                        variant="single-column"
                        :items="taxRates"
                        :hasRemoveIcon="false"
                        :selected="newRepair.laborTax"
                        placeholder="Mwst auswählen"
                        @onItemSelect="handleLaborTaxSelect"
                    />
                </div>
                <span class="Hint">
                    Den Preis in Euro angeben.
                </span>
            </div>
            <div class="InputWrap">
                <label for="repairDescription">Beschreibung</label>
                <textarea
                    class="Input"
                    id="repairDescription"
                    v-model="newRepair.description"
                    placeholder="Reparatur Beschreibung"
                    required
                />
            </div>
        </Modal> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { priceToEuroString, round2d } from '@/lib/helper';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { subDays, formatISO } from 'date-fns';
import Headline from '@/components/Headline';
import Modal from '@/components/widgets/Modal';
import Button from '@/components/widgets/Button';
import Dropdown from '@/components/widgets/Dropdown';
import Toolbar from '@/components/Toolbar';
import BackArrow from '@/components/BackArrow.vue';

import ServiceEntryTable from './components/ServiceEntryTable.vue';

import DeviceCalibration from './components/services/DeviceCalibration.vue';
import EngineOilChange from './components/services/EngineOilChange.vue';
import TuvInspection from './components/services/TuvInspection.vue';
import TransmissionOilChange from './components/services/TransmissionOilChange.vue';
import WheelChange from './components/services/WheelChange.vue';
import CarService from './components/services/CarService.vue';

import ServiceSelection from './components/ServiceSelection.vue';

export default {
    name: 'Car',
    components: {
        Headline,
        ServiceEntryTable,
        Modal,
        Button,
        Toolbar,
        // Dropdown,
        BackArrow,
        EngineOilChange,
        TuvInspection,
        TransmissionOilChange,
        ServiceSelection,
        WheelChange,
        DeviceCalibration,
        CarService,
    },
    data() {
        return {
            selectedService: null,
            repairCategories: [
                { id: 'tires', value: 'Reifen', color: '#FAD2E1' },
                { id: 'brakes', value: 'Bremsen', color: '#ffeaea' },
                { id: 'engine', value: 'Motor', color: '#BFD7ED' },
                { id: 'exhaust', value: 'Auspuff', color: '#C5E0DC' },
                { id: 'suspension', value: 'Fahrwerk', color: '#ECD5E3' },
                { id: 'electronics', value: 'Elektronik', color: '#FAE1B5' },
                { id: 'body', value: 'Karosserie', color: '#AED9E0' },
                { id: 'interior', value: 'Innenraum', color: '#E0BFC8' },
                { id: 'glass', value: 'Scheiben', color: '#D7D0C3' },
                { id: 'lights', value: 'Lichter', color: '#B5E2FA' },
                { id: 'oil', value: 'Ölwechsel', color: '#E7D2FA' },
                { id: 'other', value: 'Sonstiges', color: '#F2C6DE' },
            ].sort((a, b) => a.value.localeCompare(b.value)),
            taxRates: [{ id: 0, value: '0% Mwst' }, { id: 0.19, value: '19% Mwst' }],
            newRepair: {
                category: null,
                partCost: 0,
                partTax: '0% Mwst',
                laborCost: 0,
                date: new Date(),
                laborTax: '0% Mwst',
            },
            isModalOpen: false,
            isAddingEntry: false,
            activeCarId: null,
            repairHistory: [],
            reminders: [],
            isFetchingData: false,
            modal: {
                delete: {
                    isActive: false,
                },
                car: {
                    isActive: false,
                },
            },
            isEditing: false,
            selectedCategory: null,
            url: `${process.env.VUE_APP_API_BASE_URL}/cpanel/cars`,
            data: {},
        };
    },
    computed: {
        ...mapGetters(['car']),
        carId() {
            return this.car?.currentInstallation?.licenseNumber || this.car.vin;
        },
        spendByCategory() {
            const totalSpend = this.repairHistory.reduce((acc, entry) => acc + entry.totalCost, 0);

            const spendMap = this.repairHistory.reduce((acc, entry) => {
                const { category, totalCost } = entry;
                if (!acc[category.id]) {
                    acc[category.id] = {
                        value: category.value,
                        spend: 0,
                        id: category.id,
                        color: category.color,
                    };
                }
                acc[category.id].spend += totalCost;
                return acc;
            }, {});

            const spendCategory = Object.values(spendMap).map(category => {
                return {
                    ...category,
                    width: (category.spend / totalSpend) * 100,
                    color: category.color,
                };
            });

            return spendCategory.sort((a, b) => b.width - a.width);
        },
        filteredRepairHistory() {
            if (!this.selectedCategory || this.selectedCategory.id === 'all') {
                return this.repairHistory;
            }
            return this.repairHistory.filter(entry => {
                return entry.category.id === this.selectedCategory.id;
            });
        },
        totalIncomeString() {
            const prefix = 'Gesamteinnahmen';
            const income = priceToEuroString(0);
            return `${prefix}: <b>${income}</b>`;
        },
        totalRepairCostString() {
            const prefix = 'Gesamtkosten';
            const categoryCost = this.spendByCategory.find(
                category => category?.id === this?.selectedCategory?.id,
            );
            const cost = this.selectedCategory
                ? priceToEuroString(categoryCost?.spend || 0)
                : this.totalRepairCost;

            const costString = this.selectedCategory?.id
                ? `in der Kategorie <b>${this.selectedCategory.value}</b>: <b>${cost}</b>`
                : `: <b>${cost}</b>`;
            return `${prefix}${costString}`;
        },
        totalRepairCost() {
            return priceToEuroString(
                this.repairHistory.reduce((acc, entry) => acc + entry.totalCost, 0),
            );
        },
        newRepairDate: {
            get() {
                return formatISO(this.newRepair.date, { representation: 'date' });
            },
            set(value) {
                this.newRepair.date = new Date(value);
            },
        },
        actionHistory() {
            return this.car?.actionHistory || [];
        },
    },
    methods: {
        ...mapActions(['getCar']),
        formatISO,
        priceToEuroString,
        updateSelectedService(service) {
            console.log(service);
            this.selectedService = service;
        },
        async updateEngineOilChange() {
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/engine_oil_change${
                    this.isEditing ? `/${this.isEditing}` : ''
                }`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        async updateWheelChange() {
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/wheel_change${this.isEditing ? `/${this.isEditing}` : ''}`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        async updateTransmissionOilChange() {
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/transmission_oil_change${
                    this.isEditing ? `/${this.isEditing}` : ''
                }`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        async updateTUV() {
            console.log(this.car);
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/tuv${this.isEditing ? `/${this.isEditing}` : ''}`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        async updateDeviceCalibration() {
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/device_calibration${
                    this.isEditing ? `/${this.isEditing}` : ''
                }`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        async updateCarService() {
            const uuid = this.car.uuid;
            const method = this.isEditing ? 'put' : 'post';
            return axios[method](
                `${this.url}/${uuid}/service${this.isEditing ? `/${this.isEditing}` : ''}`,
                this.data,
                {
                    withCredentials: true,
                },
            );
        },
        updateData(data) {
            this.data = data;
        },
        async handleServiceAdd() {
            const service = this.selectedService;
            this.isAddingEntry = true;
            console.log('service', service);
            console.log('data', this.data);
            console.log('car', this.car);

            try {
                if (service === 'engineOilChange') {
                    await this.updateEngineOilChange();
                } else if (service === 'wheelChange') {
                    await this.updateWheelChange();
                } else if (service === 'transmissionOilChange') {
                    await this.updateTransmissionOilChange();
                } else if (service === 'tuvInspection') {
                    await this.updateTUV();
                } else if (service === 'deviceCalibration') {
                    await this.updateDeviceCalibration();
                } else if (service === 'carService') {
                    await this.updateCarService();
                }

                this.$toasted.success('Eintrag wurde gespeichert.');
                this.isAddingEntry = false;
                this.isEditing = false;
                this.closeModal();
                this.fetchCarData();
            } catch (error) {
                console.log(error);
                this.$toasted.error('Eintrag konnte nicht gespeichert werden.');
                this.isAddingEntry = false;
                this.isEditing = false;
            }
            this.isAddingEntry = false;
        },
        resetSelectedService() {
            this.selectedService = null;
            this.data = {};
        },
        async handleEntryRemove({ entry }) {
            await axios.delete(`${this.url}/${this.car.uuid}/actions/${entry.uuid}`, {
                withCredentials: true,
            });
            this.$toasted.success('Eintrag wurde gelöscht.');
            this.fetchCarData();
        },
        handleEntryClick({ entry }) {
            const topicToService = {
                WHEEL_CHANGE: 'wheelChange',
                TRANSMISSION_OIL_CHANGE: 'transmissionOilChange',
                ENGINE_OIL_CHANGE: 'engineOilChange',
                CAR_SERVICE: 'carService',
                DEVICE_CALIBRATION: 'deviceCalibration',
                TUV: 'tuvInspection',
                LABO_DATA_EXPORT: 'laboDataExport',
            };
            this.selectedService = topicToService[entry.topic];
            this.data = { ...entry };

            this.isEditing = entry.uuid;
            this.isModalOpen = true;
            console.log('entry', entry);
        },
        renderCategoryLabel(category) {
            setTimeout(() => {
                const element = document.querySelector(`.CategoryChunk#${category.id}`);
                const label = element.querySelector('.CategoryChunkLabel');
                const tooltip = element.querySelector('.Tooltip');
                if (!label || !element) return;

                const labelWidth = label.offsetWidth;
                const elementWidth = element.offsetWidth;

                if (labelWidth + 20 > elementWidth) {
                    label.classList.add('is-hidden');
                } else {
                    tooltip.classList.add('is-hidden');
                }
            }, 200);
        },
        handleCategorySelect({ item }) {
            this.selectedCategory = item;
        },
        handleRepairCategorySelect({ item }) {
            this.newRepair.category = item;
        },
        handlePartTaxSelect({ item }) {
            this.newRepair.partTax = item;
        },
        handleLaborTaxSelect({ item }) {
            this.newRepair.laborTax = item;
        },
        openNewEntryModal() {
            this.newRepair = {
                category: null,
                partCost: 0,
                partTax: '0% Mwst',
                laborCost: 0,
                date: new Date(),
                laborTax: '0% Mwst',
            };
            this.isModalOpen = true;
        },
        addRepairEntry() {
            try {
                const partTax =
                    typeof this.newRepair.partTax === 'string'
                        ? this.taxRates.find(tax => tax.value === this.newRepair.partTax)
                        : this.newRepair.partTax;
                const laborTax =
                    typeof this.newRepair.laborTax === 'string'
                        ? this.taxRates.find(tax => tax.value === this.newRepair.laborTax)
                        : this.newRepair.laborTax;
                const totalPartCost = this.newRepair.partCost * (100 + partTax.id);
                const totalLaborCost = this.newRepair.laborCost * (100 + laborTax.id);
                const totalCost = totalPartCost + totalLaborCost;

                if (!this.newRepair.category) {
                    this.$toasted.error('Bitte wähle eine Kategorie aus.');
                    return;
                }
                const entry = {
                    id: uuidv4(),
                    ...this.newRepair,
                    partTax,
                    laborTax,
                    date: new Date(),
                    totalCost,
                };

                const index = this.repairHistory.findIndex(e => e.id === entry.id);

                if (index !== -1) {
                    this.repairHistory.splice(index, 1, entry);
                } else {
                    this.repairHistory.splice(0, 0, entry);
                }

                this.closeModal();
                this.$toasted.success('Eintrag wurde gespeichert.');
                this.repairHistory = this.repairHistory.sort(
                    (a, b) => new Date(b.date) - new Date(a.date),
                );
                this.newRepair = {
                    category: '',
                    partCost: 0,
                    partTax: 0,
                    laborCost: 0,
                    laborTax: 0,
                    date: new Date(),
                };
                this.repairCategories.forEach(category => this.renderCategoryLabel(category));
            } catch (error) {}
        },
        fetchCarData() {
            this.isFetchingData = true;
            this.getCar(this.$route.params.id)
                .then(() => {
                    this.isFetchingData = false;
                })
                .catch(() => {
                    this.isFetchingData = false;
                });
        },

        closeModal() {
            this.isModalOpen = false;
            this.isEditing = false;
            console.log('close');
            this.resetSelectedService();
            setTimeout(() => {
                this.selectedService = null;
            }, 400);
        },
    },
    async mounted() {
        const uuid = this.$route.params.id;
        await this.getCar(uuid);
        this.$emit('onFinishLoading');
        console.log('car', this.car);
        // for (let i = 1; i <= 20; i++) {
        //     const randomEntry = {
        //         id: i,
        //         category: this.repairCategories[Math.max(0, Math.floor(Math.random() * 10))],
        //         date: subDays(new Date(), Math.floor(Math.random() * 365)),
        //         description: 'Beschreibung ' + i,
        //         partCost: Math.floor(Math.random() * 100),
        //         partTax: this.taxRates[Math.max(0, Math.floor(Math.random() * 2))],
        //         laborCost: Math.floor(Math.random() * 100),
        //         laborTax: this.taxRates[Math.max(0, Math.floor(Math.random() * 2))],
        //     };
        //     this.repairHistory.push({
        //         ...randomEntry,
        //         totalCost:
        //             randomEntry.partCost * (100 + Number(randomEntry.partTax.id)) +
        //             randomEntry.laborCost * (100 + Number(randomEntry.laborTax.id)),
        //     });
        // }
        // this.repairHistory.sort((a, b) => new Date(b.date) - new Date(a.date));
        // this.repairCategories.forEach(category => this.renderCategoryLabel(category));
    },
};
</script>

<style lang="scss">
.Car {
    @extend %contentWrapper;
    @extend %page;
    .CategorySpendBar {
        display: flex;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        background: var(--color-white);
        padding: 4px;
        border-radius: 7px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

        .CategoryChunk {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: black;
            white-space: nowrap;
            position: relative;
            border-right: 1px solid rgba(0, 0, 0, 0.5);
            cursor: pointer;
            transform: translateZ(0);
            transition: all 0.3s cubic-bezier(0.24, 0.6, 0.36, 1);

            .CategoryChunkLabel {
                display: flex;
                flex-direction: column;
                justify-content: center;

                span:first-child {
                    margin-bottom: -6px;
                }

                &.is-hidden {
                    display: none;
                }
            }

            .Spend {
                font-size: 12px;
            }

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
                border-right: none;
            }

            &:hover {
                filter: brightness(1.05);

                .Tooltip:not(.is-hidden) {
                    display: block;
                }
            }

            @media (max-width: 400px) {
                font-size: 8px;
                padding: 0 2px;
            }
        }
    }

    .Tooltip {
        display: none;
        position: absolute;
        background-color: #000000;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 14px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-80%) translateZ(0);
        z-index: 1000;

        &.is-hidden {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            right: 0%;
            border-width: 5px;
            border-style: solid;
            border-color: #000000 transparent transparent transparent;
            transform: translateX(-200%) translateZ(0);
        }

        @media (max-width: 400px) {
            font-size: 12px;
        }
    }

    .RepairHistory {
        margin-top: 0px;
        h3 {
            font-size: 20px;
            margin-top: 0px;
            margin-bottom: 20px;
            font-weight: 700;
        }
    }
    .Modal {
        .Selected {
            width: 100%;
        }
        .Row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
        }
    }

    > .InputWrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .Input,
        textarea {
            padding: 8px;
            border-radius: 5px;
            border: 1px solid var(--color-text-inactive);
            background: var(--color-white);

            &:focus {
                outline: none;
                border-color: color(blue-light);
            }
        }

        textarea {
            min-height: 100px;
        }

        .Hint {
            font-size: 12px;
            color: #595959;
            margin-top: 5px;
        }
    }

    .Back-Arrow {
        top: 10px;
        left: -10px;
    }
    .stepper-box {
        box-shadow: none;
    }
}
</style>
