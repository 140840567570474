<template>
    <ServiceEntryForm
        title="TÜV Prüfung"
        description="Details zur TÜV Prüfung eingeben und eine Erinnerung festlegen."
        :fields="fields"
        @update="handleFormUpdate"
    />
</template>

<script>
import ServiceEntryForm from '../ServiceEntryForm.vue';
import { addYears, subMonths, format, differenceInMonths } from 'date-fns';

export default {
    components: {
        ServiceEntryForm,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        fields() {
            return [
                {
                    key: 'date',
                    label: 'Datum der Prüfung',
                    type: 'date',
                    defaultValue: new Date().toISOString().substring(0, 10),
                    placeholder: 'Choose a date',
                },
                {
                    key: 'mileage',
                    label: 'KM-Stand bei Prüfung',
                    type: 'number',
                    defaultValue: '',
                    placeholder: 'KM-Stand eingeben',
                    min: 1,
                    required: true,
                    max: 100,
                },
                {
                    key: 'monthsMargin',
                    label: 'Erinnerung',
                    type: 'reminder',
                    allowedUnits: [{ label: 'Monat(e)', value: 'months' }],
                    defaultValue: { number: 2, unit: 'months' },
                    monthsMargin: 2,
                },
            ].map(field => {
                if (field.key === 'monthsMargin') {
                    field.defaultValue = {
                        number: this.data.monthsMargin || 2,
                        unit: 'months',
                    };
                } else {
                    field.defaultValue = this.data[field.key] || field.defaultValue;
                }
                return field;
            });
        },
    },

    methods: {
        handleFormUpdate(formData) {
            formData = {
                ...formData,
                monthsMargin: formData.monthsMargin.number || 12,
            };
            this.$emit('update', formData);
        },
    },
};
</script>
