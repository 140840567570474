export const topicToService = {
    PASSENGER_TRANSPORT_CERTIFICATE_EXPIRY: 'passengerTransportCertificateExpiry',
    DRIVER_LICENSE_EXPIRY: 'driverLicenseExpiry',
    WHEEL_CHANGE: 'wheelChange',
    TRANSMISSION_OIL_CHANGE: 'transmissionOilChange',
    ENGINE_OIL_CHANGE: 'engineOilChange',
    CAR_SERVICE: 'carService',
    DEVICE_CALIBRATION: 'deviceCalibration',
    TUV: 'tuvInspection',
    LABO_DATA_EXPORT: 'laboDataExport',
};

export const topicToLabel = {
    PASSENGER_TRANSPORT_CERTIFICATE_EXPIRY: 'P-Schein',
    DRIVER_LICENSE_EXPIRY: 'Führerschein',
    WHEEL_CHANGE: 'Reifenwechsel',
    TRANSMISSION_OIL_CHANGE: 'Getriebeölwechsel',
    ENGINE_OIL_CHANGE: 'Motorölwechsel',
    CAR_SERVICE: 'Inspektion',
    DEVICE_CALIBRATION: 'Eichung',
    TUV: 'TÜV',
    LABO_DATA_EXPORT: 'Labordatenexport',
};

export const serviceToLabel = {
    passengerTransportCertificateExpiry: 'P-Schein',
    driverLicenseExpiry: 'Führerschein',
    wheelChange: 'Reifenwechsel',
    transmissionOilChange: 'Getriebeölwechsel',
    engineOilChange: 'Motorölwechsel',
    carService: 'Inspektion',
    deviceCalibration: 'Eichung',
    tuvInspection: 'TÜV',
    laboDataExport: 'Labordatenexport',
};

export const serviceToTopic = {
    passengerTransportCertificateExpiry: 'PASSENGER_TRANSPORT_CERTIFICATE_EXPIRY',
    driverLicenseExpiry: 'DRIVER_LICENSE_EXPIRY',
    wheelChange: 'WHEEL_CHANGE',
    transmissionOilChange: 'TRANSMISSION_OIL_CHANGE',
    engineOilChange: 'ENGINE_OIL_CHANGE',
    carService: 'CAR_SERVICE',
    deviceCalibration: 'DEVICE_CALIBRATION',
    tuvInspection: 'TUV',
    laboDataExport: 'LABO_DATA_EXPORT',
};

export const services = [
    {
        name: 'wheelChange',
        color: '#88e394',
    },
    {
        name: 'transmissionOilChange',
        color: '#64b5f6',
    },
    {
        name: 'engineOilChange',
        color: '#ffab91',
    },
    {
        name: 'carService',
        color: '#80f4ff',
    },
    {
        name: 'deviceCalibration',
        color: '#e0a6ea',
    },
    {
        name: 'tuvInspection',
        color: '#ffe082',
    },
    {
        name: 'laboDataExport',
        color: '#91e283',
    },
];

export const servicesByKeys = services.reduce((acc, service) => {
    acc[serviceToTopic[service.name]] = service;
    return acc;
}, {});
