<template>
    <div>
        <h4>{{ title }}</h4>
        <p>{{ description }}</p>
        <br />
        <div v-for="field in fields" :key="field.key" class="form-group">
            <p v-if="field.type === 'reminder'" style="margin: 20px 10px;">
                Legen Sie eine Erinnerung fest
            </p>

            <ReminderInput
                v-if="field.type === 'reminder'"
                @update="handleReminderInput"
                :value="field.defaultValue"
                :allowedUnits="field.allowedUnits"
            />
            <Input
                v-else
                :label="field.label"
                :marginTop="field.marginTop || 4"
                :type="field.type"
                :value="formData[field.key]"
                :pattern="field.pattern"
                :min="field.min"
                :options="field.options"
                :max="field.max"
                :placeholder="field.placeholder"
                :keyName="field.key"
                :isDisabled="field.isDisabled || false"
                :direction="field.direction || 'vertical'"
                @onKeyPress="handleKeyPress"
            />
        </div>
    </div>
</template>

<script>
import Input from '@/components/widgets/Input.vue';
import ReminderInput from '@/components/widgets/ReminderInput.vue';

const unitToLabel = {
    days: 'Tag(e)',
    weeks: 'Woche(n)',
    months: 'Monat(e)',
    years: 'Jahr(e)',
};
export default {
    components: {
        Input,
        ReminderInput,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
    },
    data() {
        const formData = {};
        this.fields.forEach(field => {
            formData[field.key] = field.defaultValue || '';
        });
        return {
            formData,
        };
    },

    methods: {
        handleReminderInput(payload) {
            const reminder = {
                km: {
                    mileageMargin: payload,
                },
                months: {
                    monthsMargin: payload,
                },
            };

            this.formData = {
                ...this.formData,
                ...reminder[payload.unit],
            };
            this.$emit('update', { ...this.formData });
        },
        handleKeyPress(payload) {
            const key = Object.keys(payload)[0];
            this.formData[key] = payload[key];

            this.$emit('update', { ...this.formData });
        },
    },
};
</script>

<style scoped>
h4 {
    margin-top: 0;
    font-weight: 700;
}
.hint {
    color: #666;
    font-size: 0.8em;
    margin-left: 0.5rem;
}
.form-group {
    margin-bottom: 1rem;
}
</style>
