<template>
    <ServiceEntryForm
        title="Fahrzeuginspektion"
        description="Geben Sie die Details der Fahrzeuginspektion ein und legen Sie Erinnerungen fest."
        :fields="fields"
        @update="handleFormUpdate"
    />
</template>

<script>
import ServiceEntryForm from '../ServiceEntryForm.vue';

export default {
    components: {
        ServiceEntryForm,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        fields() {
            return [
                {
                    key: 'date',
                    label: 'Datum der Inspektion',
                    type: 'date',
                    defaultValue: new Date().toISOString().substring(0, 10),
                    placeholder: 'Datum wählen',
                },
                {
                    key: 'mileage',
                    label: 'KM-Stand bei Inspektion',
                    type: 'number',
                    defaultValue: '',
                    placeholder: 'KM-Stand eingeben',
                    min: 0,
                },
                {
                    key: 'mileageLimit',
                    label: 'Verbleibende Laufleistung',
                    type: 'number',
                    defaultValue: 60000,
                    placeholder: 'Verbleibende Laufleistung',
                    min: 0,
                },
                {
                    key: 'mileageMargin',
                    label: 'Erinnerung',
                    type: 'reminder',
                    allowedUnits: [{ label: 'KM', value: 'km' }],
                    defaultValue: { number: 10000, unit: 'km' },
                },
            ].map(field => {
                if (field.key === 'mileageMargin') {
                    field.defaultValue = {
                        number: this.data.mileageMargin || 10000,
                        unit: 'km',
                    };
                } else {
                    field.defaultValue = this.data[field.key] || field.defaultValue;
                }
                return field;
            });
        },
    },
    methods: {
        handleFormUpdate(formData) {
            formData = {
                ...formData,
                mileageMargin: formData.mileageMargin.number || 10000,
            };
            this.$emit('update', formData);
        },
    },
};
</script>
