var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reminder-setup"
  }, [_vm._v(" Erinnerung "), _c('Input', {
    attrs: {
      "value": _vm.value.number,
      "keyName": 'number',
      "type": "number",
      "placeholder": "Zahl eingeben"
    },
    on: {
      "onKeyPress": _vm.handleUpdate
    }
  }), _c('Input', {
    class: [_vm.allowedUnits.length === 1 ? 'disabled' : ''],
    attrs: {
      "value": _vm.allowedUnits.find(function (unit) {
        return unit.value === _vm.value.unit;
      }).label,
      "keyName": 'unit',
      "type": _vm.allowedUnits.length === 1 ? 'text' : 'select',
      "options": _vm.allowedUnits,
      "placeholder": "Einheit wählen"
    },
    on: {
      "onKeyPress": _vm.handleUpdate
    }
  }), _vm._v(" vor Fälligkeit. ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }