<template>
    <div class="reminder-setup">
        Erinnerung
        <Input
            :value="value.number"
            :keyName="'number'"
            type="number"
            placeholder="Zahl eingeben"
            @onKeyPress="handleUpdate"
        />
        <Input
            :value="allowedUnits.find(unit => unit.value === value.unit).label"
            :keyName="'unit'"
            :type="allowedUnits.length === 1 ? 'text' : 'select'"
            :class="[allowedUnits.length === 1 ? 'disabled' : '']"
            :options="allowedUnits"
            placeholder="Einheit wählen"
            @onKeyPress="handleUpdate"
        />
        vor Fälligkeit.
    </div>
</template>

<script>
import Input from '@/components/widgets/Input.vue'; // Ensure the path to Input component is correct

export default {
    components: {
        Input,
    },
    props: {
        allowedUnits: {
            type: Array,
            default: () => [
                { label: 'KM', value: 'km' },
                { label: 'Tag(e)', value: 'days' },
                { label: 'Woche(n)', value: 'weeks' },
                { label: 'Monat(e)', value: 'months' },
                { label: 'Jahr(e)', value: 'years' },
            ],
        },
        value: {
            type: Object,
            default: () => ({ number: 1, unit: 'days' }),
        },
    },
    data() {
        return {
            unit: this.value.unit,
            number: this.value.number,
        };
    },
    methods: {
        handleUpdate(value) {
            const key = Object.keys(value)[0];
            if (key === 'number') {
                this.updateNumber({ number: Number(value.number) });
            } else if (key === 'unit') {
                this.updateUnit({ unit: value.unit });
            }
        },
        updateNumber(value) {
            this.number = value.number;
            this.$emit('update', { number: value.number, unit: this.unit });
        },
        updateUnit(value) {
            this.unit = value.unit;
            this.$emit('update', { number: this.number, unit: value.unit });
        },
    },
    mounted() {
        console.log(this.value);
    },
};
</script>

<style lang="scss">
.reminder-setup {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin: 0px;

    .InputWrap {
        width: 120px;

        &.disabled {
            pointer-events: none;
            width: fit-content;

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                padding: 5px;
                border: 1px solid transparent;
                border-radius: 5px;
                font-size: 14px;
                color: #333;
                background-color: #fff;
                cursor: pointer;
                outline: none;
                transition: border-color 0.2s;
                text-align: center;
                text-align-last: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .Input {
            padding: 5px !important;
        }

        input,
        select {
            font-size: 14px !important;
        }
    }
}
</style>
