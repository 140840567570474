<template>
    <div class="buttons">
        <Button
            variant="minimal"
            size="large"
            class="button service-button gearbox-change"
            @onClick="submitService('transmissionOilChange')"
        >
            <i class="ri-tools-line"></i>
            <span> Getriebeölwechsel</span>
        </Button>
        <Button
            variant="minimal"
            size="large"
            class="button service-button tuv-inspection"
            @onClick="submitService('tuvInspection')"
        >
            <i class="ri-shield-check-line"></i>
            <span> TÜV Prüfung</span>
        </Button>
        <Button
            variant="minimal"
            size="large"
            class="button service-button calibration"
            @onClick="submitService('deviceCalibration')"
        >
            <i class="ri-timer-line"></i>
            <span> Eichung</span>
        </Button>
        <Button
            variant="minimal"
            size="large"
            class="button service-button motor-oil-change"
            @onClick="submitService('engineOilChange')"
        >
            <i class="ri-dashboard-line"></i>
            <span> Motorölwechsel</span>
        </Button>
        <Button
            variant="minimal"
            size="large"
            class="button service-button tire-change"
            @onClick="submitService('wheelChange')"
        >
            <i class="ri-steering-2-line"></i>
            <span> Reifenwechsel</span>
        </Button>
        <Button
            variant="minimal"
            size="large"
            class="button service-button service"
            @onClick="submitService('carService')"
        >
            <i class="ri-steering-2-line"></i>
            <span> Inspektion</span>
        </Button>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button';

export default {
    components: {
        Button,
    },
    methods: {
        submitService(service) {
            this.$emit('update-service', service);
        },
    },
};
</script>
<style scoped lang="scss">
.buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;

    @media (max-width: breakpoint(tabletLandscape)) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.service-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    i {
        margin-right: 8px;
    }

    .Label {
        span {
            align-self: flex-end;
            margin-left: auto;
            font-size: 16px;
        }
    }

    &:hover {
        background-color: color(grey);
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 12px;
        content: '';
        transition: all 0.3s;
    }
}

.oil-change {
    &::before {
        background-color: #91e283;
    }
    &:hover {
        &::before {
            background-color: #91e283;
            filter: brightness(1.1);
        }
    }
}

.gearbox-change {
    &::before {
        background-color: #64b5f6;
    }
    &:hover {
        &::before {
            background-color: #64b5f6;
            filter: brightness(1.1);
        }
    }
}

.tuv-inspection {
    &::before {
        background-color: #ffe082;
    }
    &:hover {
        &::before {
            background-color: #ffe082;
            filter: brightness(1.1);
        }
    }
}

.calibration {
    &::before {
        background-color: #e0a6ea;
    }
    &:hover {
        &::before {
            background-color: #e0a6ea;
            filter: brightness(1.1);
        }
    }
}

.motor-oil-change {
    &::before {
        background-color: #ffab91;
    }
    &:hover {
        &::before {
            background-color: #ffab91;
            filter: brightness(1.1);
        }
    }
}

.tire-change {
    &::before {
        background-color: #88e394;
    }
    &:hover {
        &::before {
            background-color: #88e394;
            filter: brightness(1.1);
        }
    }
}

.service {
    &::before {
        background-color: #80f4ff;
    }
    &:hover {
        &::before {
            background-color: #80f4ff;
            filter: brightness(1.1);
        }
    }
}
</style>
