<template>
    <div class="service-entry-table-wrap">
        <div class="month-section" v-for="(group, month) in groupedHistory" :key="month">
            <div class="month-header">
                <h4>{{ month }}</h4>
            </div>
            <Row :parentHover="false" :items="{ row: header }" :index="-1" class="row-header" />
            <Row
                v-for="(entry, index) in group.entries"
                :key="entry.uuid"
                :hasBadge="true"
                :items="entry"
                :isDisabled="entry.isDisabled"
                :index="index"
                @onHover="args => $emit('onRowHover', args)"
                @onLeave="args => $emit('onRowLeave', args)"
                @onClick="$emit('onHistoryEntryClick', entry)"
            />
        </div>
        <EmptyState
            v-if="!entries.length"
            message="Es wurden noch keine Service Einträge angelegt.<br> Klicke auf den Button um einen neuen Service Eintrag anzulegen."
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { distanceToString } from '@/lib/helper';
import { servicesByKeys, topicToLabel } from './services';

export default {
    name: 'ServiceEntryTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        entries: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isRemovingEntry: false,
        };
    },
    computed: {
        ...mapGetters(['contactPerson', 'businessName', 'modules']),
        hasReminderFeature() {
            return this.modules.includes('reminder');
        },
        header() {
            return [
                { name: 'Ereignis', value: '' },
                { name: 'Am', value: '' },
                { name: 'Erstellt Am', value: '' },
                { name: 'Kilometerstand', value: '' },
                { name: '', value: '' },
                {
                    name: '',
                    value: '',
                    props: {
                        style: 'width: 30px; flex:none;',
                    },
                },
            ];
        },
        groupedHistory() {
            const sortedEntries = [...this.entries].sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            console.log(sortedEntries);
            const history = sortedEntries.reduce((acc, entry) => {
                if (!entry.date) return acc;

                const month = format(new Date(entry.date), 'MMMM yyyy', { locale: de });
                if (!acc[month]) acc[month] = { entries: [] };

                acc[month].entries.push(this.formatEntry(entry));
                return acc;
            }, {});

            // sort by date, where the newest entry is at the top
            Object.keys(history).forEach(key => {
                history[key].entries.sort((a, b) => {
                    return new Date(b.row[2].value) - new Date(a.row[2].value);
                });
            });
            return history;
        },
    },
    methods: {
        formatEntry(entry) {
            const sameTopicCount = this.entries.filter(e => e.topic === entry.topic).length;
            const showTooltip = entry.date ? false : true;
            console.log(entry.date ?? true);
            const additionalProps = showTooltip
                ? {
                      tooltip: 'Upgrade auf Pro 🔓',
                      onClick: () => console.log('Upgrade auf Pro'),
                  }
                : {};

            return {
                uuid: entry.uuid,
                topic: topicToLabel[entry.topic] || entry.topic,
                isDisabled: entry.isDisabled,
                color: servicesByKeys[entry.topic]?.color || '#000',
                row: [
                    { name: 'Thema', value: topicToLabel[entry.topic] || entry.topic },
                    {
                        name: 'Am',
                        value: entry.date
                            ? format(new Date(entry.date), 'dd.MM.yyyy', { locale: de })
                            : `<span style="filter: ${
                                  !showTooltip ? 'none' : 'blur(5px)'
                              };">${format(new Date(), 'dd.MM.yyyy', { locale: de })}</span>`,
                        props: additionalProps,
                    },
                    {
                        name: 'Datum',
                        value: format(new Date(entry.createdAt), 'dd.MM.yyyy', { locale: de }),
                    },
                    {
                        name: 'Kilometerstand',
                        value: `<span style="filter: ${
                            !showTooltip ? 'none' : 'blur(5px)'
                        };">${distanceToString(entry.mileage, 'km')}</span>`,
                        props: additionalProps,
                    },
                    {
                        name: '',
                        props: {
                            keyName: 'isVisible',
                            value: entry.isDisabled ? 'Fehleintrag' : 'Bearbeiten',
                            variant: 'minimal',
                            size: 'small',
                            id: entry.uuid,
                            onClick: () => this.$emit('onServiceEntryClick', { entry }),
                        },
                        component: 'Button',
                    },
                    {
                        name: '',
                        props: {
                            keyName: 'isVisible',
                            value: entry.isDisabled ? '❌' : '🗑️',
                            variant: 'minimal',
                            size: 'small',
                            id: entry.uuid,
                            isLoading: this.isRemovingEntry,
                            style: 'width: 30px; flex:none; margin-right: 20px;',
                            tooltip: 'Eintrag löschen',
                            onClick: () => {
                                if (!this.isRemovingEntry) {
                                    this.$emit('onServiceEntryRemove', { entry });
                                }
                                this.isRemovingEntry = true;

                                setTimeout(() => {
                                    this.isRemovingEntry = false;
                                }, 1000);
                            },
                        },
                        component: entry.isDisabled ? 'span' : 'Button',
                    },
                    sameTopicCount > 1
                        ? {
                              name: '',
                              value: `ℹ️`,
                              props: {
                                  keyName: 'isVisible',
                                  value: `ℹ️`,
                                  variant: 'minimal',
                                  size: 'small',
                                  id: entry.uuid,
                                  style:
                                      'width: 30px; flex:none; position: absolute; right: 2px; top:6px; border:none; padding:0;font-size:12px; background:transparent; display:flex; align-items:center; justify-content:center; cursor:pointer; color:var(--color-text-gray-black);',
                                  tooltip: `Es gibt noch ${sameTopicCount -
                                      1} weitere Einträge für ${topicToLabel[entry.topic]}`,
                                  // onClick: () => {
                                  //     this.$toasted.show(
                                  //         `Schalten Sie das Reminder Feature frei um alle Einträge zu sehen`,
                                  //         {
                                  //             type: 'info',
                                  //             duration: 10000,
                                  //             action: {
                                  //                 text: 'Upgrade auf Pro',
                                  //                 onClick: (e, toastObject) => {
                                  //                     toastObject.goAway(0);
                                  //                     window.open(
                                  //                         `mailto:info@taxifusion.de?subject=Upgrade auf Pro&body=
                                  //                         Sehr geehrtes TaxiFusion Team, <br/><br/>
                                  //                         ich möchte gerne das Reminder Feature freischalten. Bitte kontaktieren Sie mich.
                                  //                         <br/>
                                  //                         ${
                                  //                             this.contactPerson
                                  //                                 ? `Ansprechpartner: ${this.contactPerson}`
                                  //                                 : ''
                                  //                         }
                                  //                         <br/>
                                  //                         ${
                                  //                             this.businessName
                                  //                                 ? `Firma: ${this.businessName}`
                                  //                                 : ''
                                  //                         }
                                  //                         <br/><br/>Vielen Dank!`,
                                  //                     );
                                  //                 },
                                  //             },
                                  //         },
                                  //     );
                                  // },
                              },
                          }
                        : null,
                ].filter(item => item !== null),
            };
        },
    },
};
</script>

<style scoped lang="scss">
.service-entry-table-wrap {
    width: 100%;
    overflow: auto;

    .month-section {
        margin-bottom: 60px;
    }

    .month-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 8px;
    }

    p {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 8px;
    }
}
</style>
