var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "buttons"
  }, [_c('Button', {
    staticClass: "button service-button gearbox-change",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('transmissionOilChange');
      }
    }
  }, [_c('i', {
    staticClass: "ri-tools-line"
  }), _c('span', [_vm._v(" Getriebeölwechsel")])]), _c('Button', {
    staticClass: "button service-button tuv-inspection",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('tuvInspection');
      }
    }
  }, [_c('i', {
    staticClass: "ri-shield-check-line"
  }), _c('span', [_vm._v(" TÜV Prüfung")])]), _c('Button', {
    staticClass: "button service-button calibration",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('deviceCalibration');
      }
    }
  }, [_c('i', {
    staticClass: "ri-timer-line"
  }), _c('span', [_vm._v(" Eichung")])]), _c('Button', {
    staticClass: "button service-button motor-oil-change",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('engineOilChange');
      }
    }
  }, [_c('i', {
    staticClass: "ri-dashboard-line"
  }), _c('span', [_vm._v(" Motorölwechsel")])]), _c('Button', {
    staticClass: "button service-button tire-change",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('wheelChange');
      }
    }
  }, [_c('i', {
    staticClass: "ri-steering-2-line"
  }), _c('span', [_vm._v(" Reifenwechsel")])]), _c('Button', {
    staticClass: "button service-button service",
    attrs: {
      "variant": "minimal",
      "size": "large"
    },
    on: {
      "onClick": function onClick($event) {
        return _vm.submitService('carService');
      }
    }
  }, [_c('i', {
    staticClass: "ri-steering-2-line"
  }), _c('span', [_vm._v(" Inspektion")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }